.el-select {
  cursor: pointer;

  .el-input__icon {
    position: absolute;
    top: 0;
    right: 0;

    &.el-icon-caret-top {
      transform: rotate(180deg);

      &.is-reverse {
        transform: rotate(0);
      }

      &::before {
        content: '\E6E1';
      }
    }
  }
}

.el-select-dropdown__item.selected {
  font-weight: bold;
  color: #409eff;
}
