@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  to {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  to {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  to {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  to {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }

  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }

  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.sweet-modal-icon.sweet-modal-success.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }

  to {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }

  to {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}

@-webkit-keyframes animateXMark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes animateXMark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #f8d486;
  }

  to {
    border-color: #f8bb86;
  }
}

@keyframes pulseWarning {
  0% {
    border-color: #f8d486;
  }

  to {
    border-color: #f8bb86;
  }
}

.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate;
}

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }

  to {
    background-color: #f8bb86;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }

  to {
    background-color: #f8bb86;
  }
}

.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}

@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.sweet-modal-icon {
  position: relative;
  width: 80px;
  height: 80px;
  border: 4px solid grey;
  border-radius: 50%;
  margin: auto;
  padding: 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.sweet-modal-icon.sweet-modal-error {
  border-color: #f44336;
}

.sweet-modal-icon.sweet-modal-error .sweet-modal-x-mark {
  position: relative;
  display: block;
}

.sweet-modal-icon.sweet-modal-error .sweet-modal-line {
  display: block;
  position: absolute;
  top: 37px;
  height: 5px;
  width: 47px;
  background-color: #f44336;
  border-radius: 2px;
}

.sweet-modal-icon.sweet-modal-error .sweet-modal-line.sweet-modal-left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px;
}

.sweet-modal-icon.sweet-modal-error .sweet-modal-line.sweet-modal-right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px;
}

.sweet-modal-icon.sweet-modal-warning {
  border-color: #ff9800;
}

.sweet-modal-icon.sweet-modal-warning .sweet-modal-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  margin-left: -2px;
  border-radius: 2px;
  background-color: #ff9800;
}

.sweet-modal-icon.sweet-modal-warning .sweet-modal-dot {
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 7px;
  height: 7px;
  margin-left: -3px;
  border-radius: 50%;
  background-color: #ff9800;
}

.sweet-modal-icon.sweet-modal-info {
  border-color: #039be5;
}

.sweet-modal-icon.sweet-modal-info:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  margin-left: -2px;
  border-radius: 2px;
  background-color: #039be5;
}

.sweet-modal-icon.sweet-modal-info:after {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  top: 19px;
  margin-left: -3px;
  border-radius: 50%;
  background-color: #039be5;
}

.sweet-modal-icon.sweet-modal-success {
  border-color: #4caf50;
}

.sweet-modal-icon.sweet-modal-success:after,
.sweet-modal-icon.sweet-modal-success:before {
  content: '';
  position: absolute;
  border-radius: 40px;
  width: 60px;
  height: 120px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sweet-modal-icon.sweet-modal-success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}

.sweet-modal-icon.sweet-modal-success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
}

.sweet-modal-icon.sweet-modal-success .sweet-modal-placeholder {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
  width: 80px;
  height: 80px;
  border: 4px solid rgba(76, 175, 80, 0.2);
  border-radius: 50%;
}

.sweet-modal-icon.sweet-modal-success .sweet-modal-fix {
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  width: 7px;
  height: 90px;
  background-color: #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sweet-modal-icon.sweet-modal-success .sweet-modal-line {
  display: block;
  position: absolute;
  z-index: 2;
  height: 5px;
  background-color: #4caf50;
  border-radius: 2px;
}

.sweet-modal-icon.sweet-modal-success .sweet-modal-line.sweet-modal-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sweet-modal-icon.sweet-modal-success .sweet-modal-line.sweet-modal-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sweet-modal-icon.sweet-modal-custom {
  border-radius: 0;
  border: none;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sweet-modal.theme-dark .sweet-modal-icon.sweet-modal-success .sweet-modal-fix,
.sweet-modal.theme-dark .sweet-modal-icon.sweet-modal-success:after,
.sweet-modal.theme-dark .sweet-modal-icon.sweet-modal-success:before {
  background-color: #182028;
}

.sweet-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9001;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  background: hsla(0, 0%, 100%, 0.9);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-perspective: 500px;
}

.sweet-modal-overlay.theme-dark {
  background: rgba(24, 32, 40, 0.94);
}

.sweet-modal-overlay.is-visible {
  opacity: 1;
}

.sweet-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  -webkit-box-shadow: 0 8px 46px rgba(0, 0, 0, 0.08), 0 2px 6px rgba(0, 0, 0, 0.03);
  box-shadow: 0 8px 46px rgba(0, 0, 0, 0.08), 0 2px 6px rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 640px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 2px;
  -webkit-transform: scale(0.9) translate(calc(-50% - 32px), -50%);
  transform: scale(0.9) translate(calc(-50% - 32px), -50%);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-timing-function: cubic-bezier(0.52, 0.02, 0.19, 1.02);
  transition-timing-function: cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.sweet-modal .sweet-box-actions {
  position: absolute;
  top: 12px;
  right: 12px;
}

.sweet-modal .sweet-box-actions .sweet-action-close {
  display: inline-block;
  cursor: pointer;
  color: #222c38;
  text-align: center;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
}

.sweet-modal .sweet-box-actions .sweet-action-close svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-top: -2px;
}

.sweet-modal .sweet-box-actions .sweet-action-close svg,
.sweet-modal .sweet-box-actions .sweet-action-close svg circle,
.sweet-modal .sweet-box-actions .sweet-action-close svg path,
.sweet-modal .sweet-box-actions .sweet-action-close svg polygon,
.sweet-modal .sweet-box-actions .sweet-action-close svg rect {
  fill: currentColor;
}

.sweet-modal .sweet-box-actions .sweet-action-close:hover {
  background: #039be5;
  color: #fff;
}

.sweet-modal .sweet-title {
  height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #eaeaea;
  padding-left: 32px;
  padding-right: 64px;
}

.sweet-modal .sweet-title,
.sweet-modal .sweet-title > h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sweet-modal .sweet-title > h2 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 22px;
}

.sweet-modal ul.sweet-modal-tabs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% + 32px);
  height: 100%;
  margin-left: -32px;
  overflow-x: auto;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab {
  display: block;
  height: 100%;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #222c38;
  text-decoration: none;
  text-align: center;
  height: 100%;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-title {
  display: block;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon {
  display: block;
  line-height: 1;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg {
  width: 16px;
  height: 16px;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img circle,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img path,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img polygon,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img rect,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg circle,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg path,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg polygon,
.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg rect {
  fill: currentColor;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon + span.sweet-modal-tab-title {
  line-height: 1;
  margin-top: 8px;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab:first-child a {
  padding-left: 32px;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab.active a {
  font-weight: 600;
  color: #039be5;
}

.sweet-modal ul.sweet-modal-tabs li.sweet-modal-tab.disabled a {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  pointer-events: none;
  color: #999;
}

.sweet-modal.has-tabs:not(.has-title) .sweet-title {
  height: 84px;
  line-height: 84px;
}

.sweet-modal.has-tabs.has-title ul.sweet-modal-tabs {
  width: 100%;
  height: 48px;
  margin: 0;
  border-bottom: 1px solid #eaeaea;
}

.sweet-modal.has-tabs.has-title ul.sweet-modal-tabs li.sweet-modal-tab a {
  margin-top: -4px;
}

.sweet-modal.has-tabs.has-title ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon {
  display: inline-block;
}

.sweet-modal.has-tabs.has-title ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon img,
.sweet-modal.has-tabs.has-title ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-icon svg {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 8px;
}

.sweet-modal.has-tabs.has-title ul.sweet-modal-tabs li.sweet-modal-tab a span.sweet-modal-tab-title {
  display: inline-block;
}

.sweet-modal .sweet-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 1.5;
}

.sweet-modal .sweet-content-center {
  height: 100%;
  display: flex !important;
}

.sweet-modal .sweet-content .sweet-content-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.sweet-modal .sweet-content .sweet-modal-tab:not(.active) {
  display: none;
}

.sweet-modal .sweet-content .sweet-modal-icon {
  margin-bottom: 36px;
}

.sweet-modal .sweet-buttons {
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.sweet-modal .sweet-content + .sweet-buttons {
  border-top: 1px solid #eaeaea;
}

.sweet-modal.is-alert .sweet-content {
  display: block;
  text-align: center;
  font-size: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
}

.sweet-modal.has-tabs.has-icon .sweet-content {
  padding-top: 32px;
  padding-bottom: 32px;
}

.sweet-modal.has-tabs.has-icon .sweet-content .sweet-content-content {
  padding-left: 32px;
}

.sweet-modal.has-tabs.has-icon .sweet-content .sweet-modal-icon,
.sweet-modal:not(.has-content) .sweet-modal-icon {
  margin-bottom: 0;
}

.sweet-modal.theme-dark {
  background: #182028;
  color: #fff;
}

.sweet-modal.theme-dark .sweet-box-actions .sweet-action-close {
  color: #fff;
}

.sweet-modal.theme-dark .sweet-title {
  border-bottom-color: #090c0f;
  -webkit-box-shadow: 0 1px 0 #273442;
  box-shadow: 0 1px 0 #273442;
}

.sweet-modal.theme-dark ul.sweet-modal-tabs li a {
  color: #fff;
}

.sweet-modal.theme-dark ul.sweet-modal-tabs li.active a {
  color: #039be5;
}

.sweet-modal.theme-dark ul.sweet-modal-tabs li.disabled a {
  color: #3e5368;
}

.sweet-modal.theme-dark.has-tabs.has-title ul.sweet-modal-tabs {
  border-bottom-color: #090c0f;
  -webkit-box-shadow: 0 1px 0 #273442;
  box-shadow: 0 1px 0 #273442;
}

.sweet-modal.theme-dark .sweet-content + .sweet-buttons {
  border-top-color: #273442;
  -webkit-box-shadow: 0 -1px 0 #090c0f;
  box-shadow: 0 -1px 0 #090c0f;
}

.sweet-modal .sweet-buttons,
.sweet-modal .sweet-content {
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.09s;
  transition-delay: 0.09s;
  -webkit-transition-timing-function: cubic-bezier(0.52, 0.02, 0.19, 1.02);
  transition-timing-function: cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.sweet-modal .sweet-content {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.sweet-modal .sweet-buttons {
  -webkit-transform: translateY(16px);
  transform: translateY(16px);
}

.sweet-modal.is-visible {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 1;
}

.sweet-modal.is-visible .sweet-buttons,
.sweet-modal.is-visible .sweet-content {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

.sweet-modal.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@media screen and (min-width: 601px) {
  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: scale(1) translate(-50%, -50%);
      transform: scale(1) translate(-50%, -50%);
    }

    50% {
      -webkit-transform: scale(1.02) translate(calc(-50% + 8px), -50%);
      transform: scale(1.02) translate(calc(-50% + 8px), -50%);
    }

    to {
      -webkit-transform: scale(1) translate(-50%, -50%);
      transform: scale(1) translate(-50%, -50%);
    }
  }

  @keyframes bounce {
    0% {
      -webkit-transform: scale(1) translate(-50%, -50%);
      transform: scale(1) translate(-50%, -50%);
    }

    50% {
      -webkit-transform: scale(1.02) translate(calc(-50% + 8px), -50%);
      transform: scale(1.02) translate(calc(-50% + 8px), -50%);
    }

    to {
      -webkit-transform: scale(1) translate(-50%, -50%);
      transform: scale(1) translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: 600px) {
  .sweet-modal.is-mobile-fullscreen {
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  .sweet-modal.is-mobile-fullscreen.is-visible {
    -webkit-transform: none;
    transform: none;
  }

  .sweet-modal.is-mobile-fullscreen .sweet-buttons {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
