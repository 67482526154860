.el-message {
  z-index: 9999;
}

.el-message__group.is-with-icon {
  display: flex;
}

.el-message__group.is-with-icon p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
}

.el-message--warning p {
  color: #e6a23c;
}

.el-message--error p {
  color: #f56c6c;
}
