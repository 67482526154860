* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
  background-color: #fff;
}

.view {
  margin-top: 60px;
}

.wrapper {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
}

.wrapper__mini {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

a {
  color: #292929;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #999;
  }
}

.main-title {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 48px;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 20px;
  &__simple-text {
  }
  &__gradient-text {
    color: #409eff;
    // background: linear-gradient(to right, #0fd6fc 0%, #08a7f2 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
}

.msg {
  &__info {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}

.modal {
  font-size: 18px;
  padding: 20px;
}

// swiper pagination
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}
.swiper-pagination-bullet-active {
  background: #409eff !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px !important;
}

// step
.el-step.is-simple .el-step__head {
  display: flex;
}

// input number
// chrome
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// firefox
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']:hover,
input[type='number']:focus {
  //noinspection CssInvalidPropertyValue
  -moz-appearance: number-input;
}

// other
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// hide MathJax messages
#MathJax_Message {
  display: none !important;
}

@import './flex';
@import './spaces';
@import './sizes';
@import './message';
@import './select';
@import './overflow';
