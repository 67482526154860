@import '~rc-slider/assets/index.css';

.rc-slider {
  cursor: pointer;
}
.rc-slider-rail,
.rc-slider-track {
  height: 6px !important;
  border-radius: 3px;
}

.rc-slider-track {
  height: 6px !important;
  background-color: #409eff !important;
}

.rc-slider-handle {
  width: 16px !important;
  height: 16px !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #409eff !important;
  //transition: 0.2s !important;
  user-select: none !important;

  &:hover {
    transform: translateX(-50%) scale(1.2) !important;
  }
}

// slider tooltip
.rc-slider-tooltip {
  &-inner {
    border-radius: 4px !important;
    padding: 10px !important;
    font-size: 12px !important;
    line-height: 1.2 !important;
    min-width: 10px !important;
    word-wrap: break-word !important;
    background: #303133 !important;
    color: #fff !important;
    box-shadow: none !important;
    height: auto !important;
  }

  &-placement-top &-arrow {
    border-top-color: #303133 !important;
    position: absolute !important;
    display: block !important;
    width: 0 !important;
    height: 0 !important;
    border-style: solid !important;
    left: calc((100% - 6px) / 2);
    bottom: -4px;
  }

  &-placement-top &-arrow:after {
    content: "" !important;
    bottom: 1px !important;
    margin-left: -5px !important;
    border-width: 5px !important;
    position: absolute !important;
    display: block !important;
    width: 0 !important;
    height: 0 !important;
    border-color: transparent !important;
    border-style: solid !important;
  }
}
