.el-date-picker {
  .el-date-table__row td.disabled {
    background-color: #f4f4f4;
    opacity: 1;
    cursor: not-allowed;
    color: #ccc;

    &.today.current {
      font-size: 10px;
    }
  }
}
