.d-flex {
  display: flex;
}

.fd-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
